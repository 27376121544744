<template>
  <div class="contacts section section--body">
    <h3>Повідомлення</h3>

    <div class="block">
      <div class="text">
        <h5>Служба інформації</h5>

        <a href="tel:+380686451505">+380 (68) 645 15 05</a>
        <a href="https://goo.gl/maps/aaFYxugyDoZSBCA27" target="_blank">Бердичівська міська рада</a>

        <a href="#" class="button button-outline">Надіслати звернення</a>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5140.812358572442!2d28.593678617613858!3d49.89117722030824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24c10df7e24850ff!2z0JHQtdGA0LTQuNGH0LXQstGB0LrQuNC5INCz0L7RgNC-0LTRgdC60L7QuSDRgdC-0LLQtdGC!5e0!3m2!1sru!2sua!4v1622710093219!5m2!1sru!2sua"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .contacts {
    min-height: 600px;
    display: grid;
    align-items: center;

    .block {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      padding: 2rem 0;

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        grid-gap: 1rem;
        margin: 2rem 0;
      }
    }
  }
</style>
